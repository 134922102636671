import React, { Fragment, useState } from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import Thumbnail from "../components/thumbnail";
import BlogContent from "../components/blog-content";
import Posts from "../components/posts";
import Comments from "../components/comments";
import CommentsForm from "../components/comments-form";
import TableOfContents from "../components/table-of-contents";
import Translate from "../components/translate"
import Breadcrumbs from "../components/breadcrumbs";
import useMedia from "../hooks/useMedia";
import RankingShortcode from '../components/shortcodes/ranking-shortcode';
import CalculatorShortcode from '../components/shortcodes/calculator-shortcode';
import Layout from "../components/layout";
import Icon from "../components/icon.tsx";
import resolveUrl from '../utils/urls/resolve-url';
import Faq from "../components/faq";

import BaseHead from '../components/base-head';
import siteMetdata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetdata.domain}/social-media/blog/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const Blog = ({ 
  data: { mysqlBlog, subCategories, parentAllMysqlBlog, allMysqlBlog, allMysqlFaq, allMysqlComments }, 
  pageContext: { breadcrumbs, mysqlId },
  location: { pathname },
}) => {
  const {
    title,
    mysqlImages,
    image_canvas,
    image_description,
    intro,
    published_date,
    content,
    author,
    video,
    ranking,
    blogContact,
    related_rankings,
  } = mysqlBlog;
  const isMobileResolution = useMedia(['(max-width: 991px)'], [true], false);

  const [isOpenForm, setIsOpenForm] = useState(false);
  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const [isFaq, setIsFaq] = useState(false);
  const toggleFaq = (value) => setIsFaq(value ?? !isFaq)


  return (
    <Layout pathname={pathname}>
      <article className="blog-page">
        {isMobileResolution &&<div className={[b.container, "breadcrumbs-mobile-products", b.pb3,"scroll-inner",b.my0].join(" ")}>
          <Breadcrumbs items={breadcrumbs}/>
        </div>}
        <header className="blog-header">
          {/* {video ? (
            <div className={[b.embedResponsive, b.embedResponsive21by9].join(" ")}>
              <iframe
                src={`https://www.youtube-nocookie.com/embed/${video}?rel=0&mute=1&autoplay=1`}
                className={b.embedResponsiveItem}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="video"
              ></iframe>
            </div>
          ) : (<>
            {mysqlImages && <div className={[b.dFlex, b.justifyContentCenter].join(' ')} style={{ background: '#186CE9' }}>
              <Thumbnail
                image={image_canvas ? mysqlImages[1] : mysqlImages[0]}
                className={image_canvas ? "blog-img-canvas" : "blog-img"}
                alt={image_description || ""}
              />
            </div>}
          </>)} */}
          {mysqlImages && <div className={[b.dFlex, b.justifyContentCenter].join(' ')} style={{ background: '#186CE9' }}>
              <Thumbnail
                image={image_canvas ? mysqlImages[1] : mysqlImages[0]}
                className={image_canvas ? "blog-img-canvas" : "blog-img"}
                alt={image_description || ""}
              />
            </div>}
          <div className="blog-header-text">
            <div className={[b.container, b.py4].join(" ")}>
              {!isMobileResolution &&<div className={b.mb3}><Breadcrumbs items={breadcrumbs} textWhite /></div>}
              <h1 className={["big-title", b.mb3].join(" ")}>{title}</h1>
              <p className="blog-lead">{intro}</p>
              {author && 
                <div className={["blog-author-text", "blog-header"].join(" ")}>
                  <div className={["blog-author", b.dFlex, b.alignItemsCenter].join(" ")}>
                    <Thumbnail image={author.mysqlImage} className="blog-author-img" alt={author.name}/>
                    <span className={["blog-author-text", b.ml3].join(" ")}>
                      {author.name}, {published_date}
                    </span>
                  </div>
                </div>
              }
            </div>
          </div>
        </header>
        <div className={b.container}>
          {content && (
            <CalculatorShortcode value={content}>
              {({ startHalf, calculator, endHalf }) => (<>
                {calculator && <div className="calculator-wrapper">{calculator}</div>}
                <RankingShortcode value={endHalf || startHalf} type={{ big: ranking, small: related_rankings?.split(',').filter(Boolean) }}>
                   {(fragments) => {
                     const bigRanking = fragments[0].isRankingWidget ? fragments.shift().ranking : null;
       
                     return (
                       <>
                         <div className={["blog-content", b.row, b.mtLg3,'blueToGray', 'dark-li'].join(" ")} id="content">
                           {bigRanking && <div className={`${b.colXl12} dark-theme-listing`}>
                             {bigRanking}
                           </div>}
       
                           <div className={`${b.colXl8} dark-theme- ${b.mb3}`}>
                             {isMobileResolution && <BlogContact contact={blogContact} style={{ position: 'relative', top: '-15px' }} />}
                             
                             {fragments?.map(({ slice, ranking }, i) => (<Fragment key={i}>
                               {slice && <BlogContent content={slice} />}
                               {ranking}
                             </Fragment>))}
       
                             {allMysqlFaq?.nodes.length > 0 && (
                               <>
                                 <div className="post-content">
                                   <h2>
                                     <Translate tKey="Often asked questions"/>
                                   </h2>
                                   
                                   {allMysqlFaq.nodes.map(({ title, text }, i) => <Faq title={title} text={text} key={i} />)}
                               
                                   <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                                     <button className={[b.btn,"btn-light","btn-cta","button-256",].join(" ")}  onClick={() => { toggleForm();toggleFaq(true); }}>
                                       <Icon size={20} icon="Question-mark" className={[b.mr1, "icon-blog"].join(" ")}/>
                                       <Translate tKey="Ask your own question"/>
                                     </button>
                                   </div>
                                 </div>
                               </>
                             )}
                           </div>
                           <div className={[b.colLg4,b.ptLg5,b.pbLg3].join(' ')}>
                            <TableOfContents selector="#content .post-content h2, #content .post-content h3, #content .post-content h4" intermediary>
                              <BlogContact contact={blogContact} />
                            </TableOfContents>
                           </div>
                         </div>
                       </>
                     );
                   }}
                 </RankingShortcode>
              </>)}
            </CalculatorShortcode>
          )}

          {subCategories.nodes.length > 0 && 
            <div className={[b.mt4, b.mb3, b.mtLg5, b.ptLg4].join(' ')}>
              <h2 className={["title", b.dBlock, b.mb4].join(' ')}><Translate tKey="Topics in category:" /> {title}</h2>
              
              <div className={['subcategory-row', b.row].join(' ')}>
                {subCategories.nodes.map(({path,term, term_normalized}, i) => (
                  <div className={[b.colMd6, b.colLg4, b.colXl3, b.px1].join(' ')} key={i}>
                    <Link to={resolveUrl(path, term_normalized)}>
                      <div className={['category-box',b.mt2,b.dFlex].join(' ')}>
                        <div className='category-bl'/>
                        <div className={b.p3}>{term}</div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          }
                      
          <Comments items={allMysqlComments.nodes} title="comments" descHeader={0} formID={mysqlId} commentType="entry"/>
          <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false);toggleFaq(false); }} formID={mysqlId} commentType="entry" faq={isFaq} />

          {mysqlBlog.is_category ? (<>
            {allMysqlBlog.nodes.length > 0 && <aside className={[b.mb5, b.pbLg5].join(" ")}>
              <h2 className={["title", b.dBlock, b.mb4,b.mt3].join(' ')}><Translate tKey="Related content" /> {mysqlBlog.title}</h2>
              <Posts items={allMysqlBlog.nodes} countItem={999} />
            </aside>}
          </>) : (<>
            {parentAllMysqlBlog.nodes.length > 0 && <aside className={[b.mb5, b.pbLg5].join(" ")}>
              <h2 className={["title", b.dBlock, b.mb4,b.mt3].join(' ')}><Translate tKey="Related content" /> {mysqlBlog.title}</h2>
              <Posts items={parentAllMysqlBlog.nodes} countItem={999} />
            </aside>}
          </>)}
        </div>
      </article>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query Blog($mysqlId: Int!, $term_id: Int, $is_category: Int, $withoutUrls: [String]) {
    mysqlBlog(status: { eq: "publish" }, mysqlId: { eq: $mysqlId }) {
      title
      description
      intro
      image_description
      url
      content
      published_date
      ranking
      image_canvas
      related_rankings
      is_category
      blogContact {
        shortname
        name
        address
        phone
        www
        working_hours
        email
        affiliate
      }
      mysqlImages {
        childImageSharp {
          gatsbyImageData(width: 2000)
        }
      }
      author {
        name
        mysqlImage {
          childImageSharp {
            gatsbyImageData(width: 70)
          }
        }
      }
    }
    subCategories: allMysqlTerms(filter: {mysqlParent: {eq: $is_category}, is_article: {ne: 0}}) {
      nodes {
        term
        term_normalized
        path
      }
    }
    parentAllMysqlBlog: allMysqlBlog(filter: {url: {nin: $withoutUrls}, mysqlId: {ne: $mysqlId}, term_id: {eq: $term_id}, is_category: {eq: 0}, description: {ne: null}, status: {eq: "publish"}}) {
      nodes {
        ...PostData
      }
    }
    allMysqlBlog: allMysqlBlog(filter: {url: {nin: $withoutUrls}, mysqlId: {ne: $mysqlId}, term_id: {eq: $is_category}, is_category: {eq: 0}, description: {ne: null}, status: {eq: "publish"}}) {
      nodes {
        ...PostData
      }
    }
    allMysqlFaq(
      filter: { item: { eq: $mysqlId }, type: { eq: "article" }, answer: { ne: null } },
      sort: { fields: priority, order: DESC }
    ) {
      nodes {
        title: question
        text: answer
        priority
      }
    }
    allMysqlComments(filter: {type: {eq: "entry"}, item: {eq: $mysqlId}}, sort: {order: DESC, fields: up}) {
      nodes {
        ...CommentData 
      }
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "article" }}) {
      nodes {
        ...SeoData
      }
    }
  }

`;

const BlogContact = ({ contact, className, style }) => {
  if (!contact) {
    return null;
  }

  return (
    <div className={className} style={style}>
      <div className={["toc", b.card, "table-of-contents",b.p3,b.mt3].join(' ')}>
        {(contact.shortname || contact.name) && <h4 className="toc-title"><Translate tKey="Contact data" /> {contact.shortname || contact.name}</h4>}
        
        {contact.name && <div className={[b.row, b.mt2, b.pt1].join(' ')}>
          <div className={[b.col4, b.colMd3, b.colLg2, b.colXl4, b.pt1].join(' ')}><Translate tKey="Firm name" /></div>
          <div className={b.col} style={{ fontSize: '18px' }}>{contact.name}</div>
        </div>}

        {contact.address && <div className={[b.row, b.mt2, b.pt1].join(' ')}>
          <div className={[b.col4, b.colMd3, b.colLg2, b.colXl4, b.pt1].join(' ')}><Translate tKey="Address" /></div>
          <div className={b.col} style={{ fontSize: '18px' }}>{contact.address}</div>
        </div>}
        
        {contact.phone && <div className={[b.row, b.mt2, b.pt1].join(' ')}>
          <div className={[b.col4, b.colMd3, b.colLg2, b.colXl4, b.pt1].join(' ')}><Translate tKey="Phone" /></div>
          <div className={b.col} style={{ fontSize: '18px' }}>{contact.phone}</div>
        </div>}
        
        {contact.www && <div className={[b.row, b.mt2, b.pt1].join(' ')}>
          <div className={[b.col4, b.colMd3, b.colLg2, b.colXl4, b.pt1].join(' ')}><Translate tKey="Website address" /></div>
          <div className={b.col} style={{ fontSize: '18px' }}>{contact.www}</div>
        </div>}
        
        {contact.working_hours && <div className={[b.row, b.mt2, b.pt1].join(' ')}>
          <div className={[b.col4, b.colMd3, b.colLg2, b.colXl4, b.pt1].join(' ')}><Translate tKey="Workhours" /></div>
          <div className={b.col} style={{ fontSize: '18px' }}>{contact.working_hours}</div>
        </div>}
        
        {contact.email && <div className={[b.row, b.mt2, b.pt1].join(' ')}>
          <div className={[b.col4, b.colMd3, b.colLg2, b.colXl4, b.pt1].join(' ')}><Translate tKey="E-mail address" /></div>
          <div className={b.col} style={{ fontSize: '18px' }}>{contact.email}</div>
        </div>}
        
        {contact.affiliate && <div className={[b.row, b.mt2, b.pt1].join(' ')}>
          <div className={[b.col4, b.colMd3, b.colLg2, b.colXl4, b.pt1].join(' ')}><Translate tKey="Application" /></div>
          <div className={b.col}>
            <a href={contact.affiliate} className={[b.btn, "btn-info", b.m0].join(" ")} target="_blank" rel="noreferrer noopener nofollow" style={{ color: '#fff', fontWeight: 500, minWidth: 'auto' }}>
              <Translate tKey="Apply for loan" />
            </a>
          </div>
        </div>}
      </div>
    </div>
  );
};