import React from 'react';

import RankingWidget from "../ranking-widget";

const RankingShortcode = ({ value, type, children }) => {
  if (type) {
    const fragments = [];

    if (typeof type?.big === 'string') {
      fragments.push({
        ranking: <RankingWidget types={type.big} theme="full" limit={999} />,
        isRankingWidget: true,
      });
  
      value = value.replace(/\[ranking-widget\]\[\/ranking-widget\]/, '');
    } 
  
    if ((Array.isArray(type?.small) && type.small.length) || typeof type === 'string') {
      const types = Array.isArray(type?.small) ? type.small : [type];
  
      if (value.includes('[small-ranking-widget')) {
        for (const type of types) {
          const smallRanking = value.match(/\[small-ranking-widget(.*?)\]\[\/small-ranking-widget\]/);
    
          if (smallRanking?.index >= 0) {
            const id = smallRanking[1]?.split('=')?.[1]?.replace(/"/g, '');
    
            fragments.push({
              slice: value.slice(0, smallRanking.index),
              ranking: <RankingWidget types={id || type} limit={999} />,
              isSmallRankingWidget: true,
            });
    
            value = value.slice(smallRanking.index + smallRanking[0].length);
          }
        }
      } else {
        let count = 1;

        for (const type of types) {
          const matchH2 = [...value.matchAll(/<h2(.*?)>(.*?)<\/h2>/gm)];
          const index = Math.min(parseInt(matchH2?.length / types.length, 10), count);

          if (matchH2[index]) {
            fragments.push({
              slice: value.slice(0, matchH2[index].index),
              ranking: <RankingWidget types={type} limit={999} />,
              isSmallRankingWidget: true,
            });
  
            value = value.slice(matchH2[index].index);
            ++count;
          }
        }
      }
    }

    if (fragments.length) {
      fragments.push({
        slice: value,
      });

      return children(fragments);
    }
  }

  return children([
    { slice: value.replace(/\[ranking-widget\]|\[\/ranking-widget\]|\[small-ranking-widget.*?\]\[\/small-ranking-widget\]/g, ''),}
  ]);
}

export default RankingShortcode;
