import React, { useState, useEffect } from 'react';
import * as b from "../../bootstrap.module.css";
import InputNumber from '../input-number';
import { ExcelFormulas, APR } from "../../../utils/excel-formulas";

const RRSOCalculator = () => {
  const [amount, setAmount] = useState(15_000);
  const [period, setPeriod] = useState(24);
  const [interest, setInterest] = useState(9);
  const [commission, setCommission] = useState(4);
  const [details, setDetails] = useState(null);

  useEffect(() => {
    setDetails(calculate(amount, period, interest, commission));
  }, [amount, period, interest, commission]);

  return (
    <form 
      className={["product-inputs", "credit-calculator", "blueToGray", b.mt4, b.p3].join(" ")} 
      onSubmit={(e) => e.preventDefault()}
    >
      <h2 className={b.mb3} style={{ fontWeight: 500 }}>Jak obliczyć RRSO kredytu?</h2>

      <div className={b.row}>
        <div className={b.colMd6}>
          <label htmlFor="amount" className={b.mb2}>
            Kwota kredytu:
          </label>

          <InputNumber
            id="amount"
            minValue={1_000}
            value={amount} 
            maxValue={1_000_000}
            updateValue={setAmount} 
            step={100}
            suffix="zł"
          />
        </div>

        <div className={[b.colMd6, b.mt3, b.mtMd0].join(' ')}>
          <label htmlFor="period" className={b.mb2}>
            Liczba miesięcznych rat:
          </label>

          <InputNumber
            id="period"
            minValue={3}
            value={period} 
            maxValue={360}
            updateValue={setPeriod} 
            step={1}
            suffix="mies"
          />
        </div>

        <div className={[b.colMd6, b.order5, b.mt3].join(' ')}>
          <label htmlFor="interest" className={b.mb2}>
            Oprocentowanie          
          </label>

          <InputNumber
            id="interest"
            minValue={0.01}
            value={interest} 
            maxValue={30}
            updateValue={setInterest} 
            step={0.01}
            suffix="%"
          />
        </div>
      
        <div className={[b.colMd6, b.order6, b.mt3].join(' ')}>
          <label htmlFor="commission" className={b.mb2}>
            Prowizja          
          </label>

          <InputNumber
            id="commission"
            minValue={0.01}
            value={commission} 
            maxValue={30}
            updateValue={setCommission} 
            step={0.01}
            suffix="%"
          />
        </div>
      </div>

      {details && (<>
        <div className={["details", b.mt4, b.p3].join(" ")}>
          <div className={b.w100}>
            <h3 className={[b.textLeft, b.mb3].join(' ')}>Wynik obliczeń</h3>
            
            <p className={b.my1}>RRSO wyniesie: <strong>{details.apr.toFixed(2)} %</strong></p>
          </div>
        </div>
      </>)}
    </form>
  );
};

const calculate = (amount, period, interest, commission) => {
  const percentage = interest / 100;
  const provision = commission / 100;

  const installment = Math.round(
    ExcelFormulas.PMT(
      percentage / 12, period, amount + amount * provision
    ) * -100
  ) / 100;
  const apr = Math.round(APR(period, amount, installment) * 10000) / 100;
  
  return {
    apr
  };
};

export default RRSOCalculator;