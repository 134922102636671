import React, { useEffect, useState } from "react";
import Icon from "../icon.tsx";
import * as b from "../bootstrap.module.css";
import { ExcelFormulas, APR } from "../../utils/excel-formulas";

const CreditsCalculator = ({
  title,
  mainContent = "installment",
  showParams = true,
  secondParam = null,
  info = null,
  translates,
}) => {
  const [state, setState] = useState({
    amount: 5000, // kwota kredytu
    time: 12, // liczba miesięcznycch rat
    interest: 10, // oprocentowanie
    commission: 3, // prowizja
  });
  const [details, setDetails] = useState([]);
  const [isVisibleResult, setVisibleResult] = useState(false);

  const changeInput = (key, value) => {
    const changed_state = { ...state };
    changed_state[key] = parseInt(100 * value) / 100.0;
    setState(changed_state);
  };

  const calculateDetails = () => {
    var amount = state.amount;
    var time = state.time;
    var provision = state.commission / 100;
    var percentage = state.interest / 100;

    var installment =
      Math.round(
        ExcelFormulas.PMT(percentage / 12, time, amount + amount * provision) *
          -100
      ) / 100;
    var apr = Math.round(APR(time, amount, installment) * 10000) / 100;
    var total = Math.round(installment * time * 100) / 100;
    var cost = Math.round((total - amount) * 100) / 100;
    var today = new Date().toLocaleDateString();
    const input_details = {
      amount,
      time,
      provision,
      percentage,
      installment,
      apr,
      total,
      cost,
      today,
    };
    setDetails(input_details);
  };

  useEffect(calculateDetails, [state]);

  const valueToLocaleString = (value) => {
    return value;
    // return value > 0 ? value.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1 ") : value;
  };

  const TypeOfValue = (value) => {
    switch (value) {
      case "installment":
        return translates["cu"];
      case "apr":
        return "%";
      default:
        return "";         
    }
    
  };

  const TitleMainValue = (value) => {
    switch (value) {
      case "installment":
        return "Rata:";
      case "apr":
        return "RRSO:";
      default:
        return "";
    }
  };

  return (
    <div className={["product-inputs", "credit-calculator", b.mt4, b.p3].join(" ")}>
      <h2 className={[b.m0].join(" ")}>{title}</h2>
      <div className={[b.row].join(" ")}>
        <div className={b.colLg3}>
          <CreditsButton
            settings={{
              value: state.amount,
              title: `${translates["Amount of credit"]}:`,
              prefix: ` ${translates["cu"]}`,
              step: 500,
              max_value: null,
              key: "amount",
              changeInput,
            }}
          />
        </div>
        <div className={b.colLg3}>
          <CreditsButton
            settings={{
              value: state.time,
              title: `${translates["Number of monthly installments"]}:`,
              prefix: ` ${translates["mths"]}`,
              step: 1,
              max_value: null,
              key: "time",
              changeInput,
            }}
          />
        </div>
        <div className={b.colLg3}>
          <CreditsButton
            settings={{
              value: state.interest,
              title: `${translates["Interest"]}:`,
              prefix: " %",
              step: 0.1,
              max_value: 100,
              key: "interest",
              changeInput,
            }}
          />
        </div>
        <div className={b.colLg3}>
          <CreditsButton
            settings={{
              value: state.commission,
              title: `${translates["Fee"]}:`,
              prefix: " %",
              step: 0.1,
              max_value: null,
              key: "commission",
              changeInput,
            }}
          />
        </div>
      </div>
      <div className={[b.m0,b.mt3].join(" ")}>
        <button className={[ b.btn, b.btnSuccess, "btn-success", "btn-cta", "button-256", b.my0,].join(" ")}
          onClick={() => { setVisibleResult(true); }}>
          <Icon size={25} icon="Calculator-mini" className={[b.mr2, "icon-width"].join(" ")} />
          {translates["Convert"]}
        </button>
      </div>
      {isVisibleResult && (
        <>
          <div className={["details", b.mt3, b.p3].join(" ")}>
            <div className={[b.col6].join(" ")}>
              <h2 className={[b.p0, b.m0].join(" ")}>
                {TitleMainValue(mainContent)}
              </h2>
              <h2 className={[b.p0, b.m0].join(" ")}>
                {valueToLocaleString(details[mainContent])}{" "}
                {TypeOfValue(mainContent)}
              </h2>
            </div>
            {secondParam && (
              <div className={[b.col6].join(" ")}>
                <h2 className={[b.p0, b.m0].join(" ")}>
                  {TitleMainValue(secondParam)}
                </h2>
                <h2 className={[b.p0, b.m0].join(" ")}>
                  {valueToLocaleString(details[secondParam])}{" "}
                  {TypeOfValue(secondParam)}
                </h2>
              </div>
            )}
          </div>
          {info && (
            <div className={["info", b.mt3, b.p3].join(" ")}>
              <div className="iconToWhite">
                <Icon color="#186CE9" size={25} icon="Info" className={["icon-blog", b.mr2].join(" ")} />
              </div>
              <div>{info}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const CreditsButton = ({
  settings: { title, key, value, prefix, step, max_value, changeInput },
}) => {
  const [clicked, setClicked] = useState(false);

  const changeInputButton = (rate, val = null) => {
    var new_value =
      val != null
        ? val
        : Math.floor((value + 0.03 + step * rate) / step) * step;
    if (new_value < 0.0) {
      new_value = 0;
    }
    if (max_value != null) {
      if (new_value > max_value) {
        new_value = max_value;
      }
    }
    
    if (Number.isFinite(new_value)) {
      changeInput(key, new_value);
    }
    
    return;
  };

  return (
    <>
      <label id={key} htmlFor={key} className={[b.mt4].join(" ")}>
        {title}
      </label>
      <div className={[b.inputGroup, b.inputGroupLg].join(" ")}>
        <div className={[b.inputGroupPrepend].join(" ")}>
          <button className={[b.btn, "btn-white", b.btnSecondary].join(" ")} onClick={(e) => changeInputButton(-1)}>
            <Icon color="#2B5087" size={25} icon="Minus-sign" className="icon-width"/>
          </button>
        </div>
        <input className={`form-control ${b.formControl} ${b.textCenter} comparison-input blackToWhite`}
          min="0"
          onBlur={() => {
            setClicked(false);
          }}
          value={value}
          onChange={(e) => {
            changeInputButton(0, parseFloat(e.currentTarget.value));
          }}
        />
        <button className={["input-mask", clicked ? b.dNone : ""].join(" ")} onClick={(e) => { setClicked(true); }}>
          {`${value}${prefix}`}
        </button>
        <div className={[b.inputGroupAppend].join(" ")}>
          <button className={[b.btn, "btn-white", b.btnSecondary].join(" ")} onClick={(e) => changeInputButton(1)}>
            <Icon color="#2B5087" size={25} icon="Plus-sign" className="icon-width"/>
          </button>
        </div>
      </div>
    </>
  );
};

export default CreditsCalculator;
