import React, { useContext } from "react";

import CreditsCalculator from "../calculators/credits";
import { TranslateContext } from '../translate';
import useProducts from "../../hooks/useProducts";
// 
import ElixirCalculator from '../forms/calculators/elixir-calculator';
import RRSOCalculator from "../forms/calculators/rrso-calculator";
import CarCalculator from "../forms/calculators/credits/car-calculator";
import ConsolidationCalculator from "../forms/calculators/credits/consolidation-calculator";
import MortgageCalculator from "../forms/calculators/credits/mortgage-calculator";
import CashLoanCalculator from "../forms/calculators/credits/cash-loan-calculator";
import AccountProfitCalculator from "../forms/calculators/accounts/account-profit-calculator";
import DepositCalculator from "../forms/calculators/accounts/deposit-calculator";
import CompoundInterestCalculator from "../forms/calculators/accounts/compound-interest-calculator";

const tags = [
  {
    // Kalkulator zdolności gotówkowej [cashworthiness]
    shortcode: "cashworthiness",
    components: (dynamicProps) => <CreditsCalculator mainContent="installment" 
    info="Poniższy kalkulator pozwoli ci głównie oszacować twoją zdolność
    kredytową. Każdy bank potrafi inaczej oszacować Twoją zdolność
    kredytową. Wartości są poglądowe."
    showParams={true} title="Jak obliczyć koszt i wysokość raty kredytu gotówkowego?" {...dynamicProps} />,
  },
  { 
    // Kalkulator sesji elixir
    shortcode: 'elixir',
    components: ElixirCalculator,
  },
  {
    // Kalkulator RRSO
    shortcode: "calculator-rrso",
    components: RRSOCalculator,
  },
  {
    // Kalkulator kredytu samochodowego
    shortcode: "calculator-credit-car",
    components: CarCalculator,
  },
  {
    // Kalkulator kredytu konsolidacyjnego
    shortcode: "credit_consolidation",
    components: ConsolidationCalculator,
  },
  {
    // Kalkulator kredytu hipotecznego
    shortcode: "calculator-mortgage",
    components: MortgageCalculator,
  },
  {
    // Kalkulator kredytu gotówkowego
    shortcode: "calculator-cash-loan",
    components: CashLoanCalculator,
  },
  {
    // Kalkulator zysku z konta oszczędnościowego
    shortcode: "calculatorinterest",
    components: AccountProfitCalculator,
  },
  {
    // Kalkulator zysku z lokaty
    shortcode: "calculatorinvestment",
    components: DepositCalculator,
  },
  {
    // Kalkulator procentu składanego
    shortcode: "calculator-compound-interest",
    components: CompoundInterestCalculator,
  },
];

const CalculatorShortcode = ({ value, children }) => {
  const { objectLists } = useProducts({ types: 'credits', subtypes: { credits: 1 }, limit: 10 });
  const { translates } = useContext(TranslateContext);

  for (const tag of tags) {
    const index = value.indexOf(`[${tag.shortcode}]`);
    
    if (index >= 0) {
      return children({
        startHalf: value.slice(0, index),
        calculator: <tag.components translates={translates} products={objectLists} />,
        endHalf: value.slice(index)
          .replace(`[${tag.shortcode}]`, '')
          .replace(`[/${tag.shortcode}]`, '')
          .replace(/^<\/p>/, ''),
      });
    }
  }

  return children({
    startHalf: value
  });
};

export default CalculatorShortcode;
