import React, { useMemo, useState } from 'react';
import * as b from "../../../bootstrap.module.css";
import InputNumber from '../../input-number';
import AllProducts from '../../../all-products';
import Icon from '../../../icon';

const DepositCalculator = () => {
  const [amount, setAmount] = useState(15_000);
  const [period, setPeriod] = useState(12);
  const [interest, setInterest] = useState(0.1);
  const [details, setDetails] = useState(null);

  const settings = useMemo(() => ({
    types: 'accounts', 
    subtypes: { accounts: '6' }, 
  }), []);

  const clearDetails = (e) => {
    if (e.target.tagName === 'INPUT' || e.target.tagName === 'BUTTON') {
      setDetails(null);
    }
  };

  const updateDetails = () => {
    setDetails(calculate(amount, period, interest));
  };

  return (
    <form 
      className={["product-inputs", "credit-calculator", "blueToGray", b.mt4, b.p3].join(" ")} 
      onSubmit={(e) => e.preventDefault()}
    >
      <h2 className={b.mb3} style={{ fontWeight: 500 }}>Kalkulator zysku z lokaty</h2>

      <div className={b.row}>
        <div 
          className={[b.colMd6, b.colXl4, b.order1].join(' ')} 
          onFocusCapture={clearDetails}
        >
          <label htmlFor="amount" className={b.mb2}>
            Kwota wpłacana na lokatę:
          </label>

          <InputNumber
            id="amount"
            minValue={1_000}
            value={amount} 
            maxValue={1_000_000}
            updateValue={setAmount} 
            step={100}
            suffix="zł"
          />
        </div>

        <div 
          className={[b.colMd6, b.colXl4, b.order2, b.mt3, b.mtMd0].join(' ')}
          onFocusCapture={clearDetails}
        >
          <label htmlFor="period" className={b.mb2}>
            Okres lokaty:
          </label>

          <InputNumber
            id="period"
            minValue={3}
            value={period} 
            maxValue={360}
            updateValue={setPeriod} 
            step={1}
            suffix="mies"
          />
        </div>

        <div className={[b.colMd12, b.colXl4, b.orderLast, b.orderXl3, b.alignSelfEnd, b.mt3, b.mtXl0].join(' ')}>
          <button 
            className={[b.btn, b.btnSuccess, "btn-success", "btn-cta", b.my0].join(" ")}
            onClick={updateDetails}
          >
            <Icon size={25} icon="Calculator-mini" className={[b.mr2, "icon-width"].join(" ")} />

            Oblicz
          </button>
        </div>

        <div 
          className={[b.colMd6, b.colXl4, b.order5, b.mt3].join(' ')}
          onFocusCapture={clearDetails}
        >
          <label htmlFor="interest" className={b.mb2}>
            Oprocentowanie          
          </label>

          <InputNumber
            id="interest"
            minValue={0.01}
            value={interest} 
            maxValue={30}
            updateValue={setInterest} 
            step={0.01}
            suffix="%"
          />
        </div>
      </div>

      {details && (<>
        <div className={["details", b.mt4, b.p3].join(" ")}>
          <div className={b.w100}>
            <h3 className={[b.textLeft, b.mb3].join(' ')}>Wyniki obliczeń</h3>
            
            <p className={b.my1}><strong style={{ fontSize: '20px' }}>Zysk z lokaty po odjęciu podatku: {details.profitNetto.toFixed(2)} zł</strong></p>
            <p className={b.my1}>Zysk: <strong>{details.profitBrutto.toFixed(2)} zł</strong></p>
            <p className={b.my1}>Podatek: <strong>{details.tax.toFixed(2)} zł</strong></p>
          </div>
        </div>

        <h3 className={[b.mt4, b.mb3].join(' ')}>Zobacz naszą ofertę lokat</h3>

        <AllProducts settings={settings} />
      </>)}
    </form>
  );
};

const calculate = (amount, period, interest) => {
  const taxBelka = 0.19;
  const interestRate = interest / 100;
  const timeInterest = period / 12;
  const frequency = Math.round(100 * timeInterest) / 100;

  const endAmountBrutto = amount * Math.pow(1 + interestRate / frequency, frequency * timeInterest);
  const endAmountNetto = amount * Math.pow(1 + (interestRate / frequency) * (1 - taxBelka), frequency * timeInterest);

  const profitBrutto = Math.round(100 * (endAmountBrutto - amount)) / 100;
  const profitNetto = Math.round(100 * (endAmountNetto - amount)) / 100;

  const tax = Math.round(100 * (profitBrutto - profitNetto)) / 100;

  return {
    tax,
    profitBrutto,
    profitNetto,
  };
};

export default DepositCalculator;