import React from "react";
import { Link } from "gatsby";
import PropsType from "prop-types";
import * as b from "../bootstrap.module.css";
import Thumbnail from "../thumbnail";
import productData from "../../utils/product-data";
import Out from "../out-product";
import Icon from "../../components/icon.tsx";
import ProductCheckboxComparison from "../product-comparison-checkbox";
import Translate from "../translate";

const toggleOpen = (e) => {
  const productAdditionalInfo = e.target.closest('.product-additional-info');
  const productButtonUp = productAdditionalInfo.querySelector(':scope .product-additional-btn-up');
  const productAdditionalData = productAdditionalInfo.querySelector(':scope .product-additional-data');

  productButtonUp.classList.toggle(b.dNone);
  productAdditionalData.classList.toggle(b.dNone);
};

const ProductItem = ({ item, settings, grid, isMobileResolution }) => {
  const newData = productData(item);

  if (!newData.visible || !newData.formVisible) {
    return null;
  }

  if (isMobileResolution || grid === "column") {
    return (
      <div>
        <div className={["product-box-mobile", b.p3].join(' ')}>
          <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter].join(" ")}>
            <div className={["product-box-img-mobile-box","dark-mode-image",].join(" ")}>
              <Link to={newData.url}><Thumbnail image={newData.image} alt={newData.name}/></Link>
            </div>
          </div>

          <div className={[b.colLg12,"products-box-name",b.pl0].join(' ')}>
            <Link className={[b.mb0,'tp-name'].join(" ")} to={newData.url}>{newData.name}</Link>
          </div>

          <div className="firm-row-likes-wrapper">
            <div className={["firm-row-likes",b.dFlex,b.px2,b.justifyContentCenter,].join(" ")}>
              <div className={["stars-container", b.mr2].join(" ")}>
                <div className="star-box" style={{ width: ` ${newData.rating * 20}% ` }}>
                  <Icon size={16} icon="Star" className={b.mr1} />
                  <Icon size={16} icon="Star" className={b.mr1} />
                  <Icon size={16} icon="Star" className={b.mr1} />
                  <Icon size={16} icon="Star" className={b.mr1} />
                  <Icon size={16} icon="Star" />
                </div>
                <div className="empty-star-box">
                  <Icon size={16} icon="Empty-star" className={b.mr1} />
                  <Icon size={16} icon="Empty-star" className={b.mr1} />
                  <Icon size={16} icon="Empty-star" className={b.mr1} />
                  <Icon size={16} icon="Empty-star" className={b.mr1} />
                  <Icon size={16} icon="Empty-star" />
                </div>
              </div>
              <p className={[b.mb0, "blackToWhite",'font-size-13',b.pt1].join(" ")}>
                {newData.rating.toFixed(1)}
              </p>
            </div>
          </div>

          <div className={["firm-row-likes-wrapper", b.mt1].join(" ")}>
            <div className={["firm-row-likes",b.dFlex,b.px2,b.justifyContentCenter,'selected-offer'].join(" ")}>
              <span>
                <Icon size={14} icon="Heart" className={["icon-heart", b.mr2].join(" ")}/>
              </span>
              <p className={[b.mr1,"blueToWhite",b.mb0,'tp-name'].join(" ")}>
                {newData.was_selected}
              </p>
              <span className={["firm-row-likes-text",'font-size-13',b.fontWeightNormal,b.pt1,'blueToWhite'].join(" ")}>
                <Translate tKey="chose this offer" />
              </span>
            </div>
          </div>
          
          <div className={b.my3}>
            {
              newData.content?.map(([name, value], i) => (
                <div className={[b.dFlex, b.justifyContentBetween].join(" ")} key={i}>
                  <p className={["product-box-fs",b.mb0,"blackToGray", b.pt1,].join(" ")}>
                    {name}
                  </p>
                  <p className={["product-box-fs2", b.mb0, "blackToWhite", 'tp-name'].join(" ")}>
                    {value}
                  </p>
                </div>
              ))
            }
          </div>  
            
          {newData.points_to_note && (           
            <div className="product-additional-info">
              <button className={['product-additional-btn-up', "btn-hidden","blueToWhite",b.w100,b.alignItemsCenter,"iconToWhite",'font-size-14',b.fontWeightNormal].join(" ")} onClick={toggleOpen}>
                <Translate tKey="Additional info" /> 
                <Icon icon="Angle-down" size={25} className={[b.mr1, "icon-blog"].join(" ")}/>
              </button>

              <div className={["product-additional-data", b.dNone].join(' ')}>
                <div className={['products-promo-adv'].join(' ')}>
                  <span className={["font-size-18",b.pb2,'blueToWhite'].join(' ')}><Translate tKey="Pay attention to that"/>:</span>
                  
                  {newData.points_to_note.split('\n').map((item, i) => item.length ? (
                    <div className={["font-size-13",b.pt1].join(' ')} key={i}>
                      <img src="/assets/apps-images/GreenCheckbox.svg" alt="Google Play" className={b.mr1} loading="lazy" width="12" height="13"/>
                      {item}
                    </div>
                  ) : null)}
                </div>

                <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                  <button className={[`btn-hidden font-size-14 blueToWhite iconToWhite ${b.textCapitalize} ${b.fontWeightNormal} ${b.mt2}`].join(" ")} onClick={toggleOpen}>
                    <span><Translate tKey="hide" /> <Translate tKey="information" /> </span>
                    <Icon size={25} icon="Angle-up" className={[b.mr1, "icon-blog"].join(" ")}/>
                  </button>
                </div>
              </div>
            </div>
          )}

          {newData.productType === "cards" && <Out className={[b.btn,"btn-info","btn-normal",b.mt1,b.mb0,b.fontWeightNormal,].join(" ")} item={item} defaultDescription="Put on card" defaultIcon="Taking-money"/>}
          {newData.productType === "firm" && <Out className={[b.btn,"btn-info","btn-normal",b.mt1,b.mb0,b.fontWeightNormal,].join(" ")} item={item} defaultDescription="Apply" defaultIcon="Taking-money"/>}
          {newData.productType === "credits" && <Out className={[b.btn,"btn-info","btn-normal",b.mt1,b.mb0,b.fontWeightNormal,].join(" ")} item={item} defaultDescription="Apply credit" defaultIcon="Taking-money"/>}
          {newData.productType === "accounts" && <Out className={[b.btn,"btn-info","btn-normal",b.mt1,b.mb0,b.fontWeightNormal,].join(" ")} item={item} defaultDescription="Create account" defaultIcon="Taking-money" />}
          
          <span className={["product-box-comparison-box","btn-hidden",b.w100,b.mt2,].join(" ")}>
            <ProductCheckboxComparison item={item} settings={settings} key={item.url}/>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={["product-box", b.pl4].join(" ")}>
      <div className={b.row}>
        <div className={[b.colSm9, b.pl0].join(" ")}>
          <div className={[b.colSm12, b.dFlex].join(" ")}>
            <Link to={newData.url} className={['font-size-18', b.mr3,b.pt1].join(' ')}>{newData.name}</Link>
            <div className="firm-row-likes-wrapper">
              <div className={["firm-row-likes","product-box-mt",b.dFlex,b.px2, b.mx2,].join(" ")}>
                <div className={["stars-container", b.mr2].join(" ")}>
                  <div className="star-box" style={{ width: ` ${newData.rating * 20}% ` }}>
                    <Icon size={16} icon="Star" className={b.mr1} />
                    <Icon size={16} icon="Star" className={b.mr1} />
                    <Icon size={16} icon="Star" className={b.mr1} />
                    <Icon size={16} icon="Star" className={b.mr1} />
                    <Icon size={16} icon="Star" />
                  </div>
                  <div className="empty-star-box">
                    <Icon size={16} icon="Empty-star" className={b.mr1} />
                    <Icon size={16} icon="Empty-star" className={b.mr1} />
                    <Icon size={16} icon="Empty-star" className={b.mr1} />
                    <Icon size={16} icon="Empty-star" className={b.mr1} />
                    <Icon size={16} icon="Empty-star" />
                  </div>
                </div>
                <p className={[b.mb0,"blackToWhite",'font-size-13',b.pt1].join(" ")}>
                  {newData.rating.toFixed(1)}
                </p>
              </div>
            </div>
            <div className="firm-row-likes-wrapper">
              <div className={["firm-row-likes","product-box-mt",b.dFlex,b.px2,'selected-offer'].join(" ")}>
                <span className={[b.dFlex, b.alignItemsCenter].join(" ")}>
                  <Icon size={14} icon="Heart" className={["icon-heart", b.mr2].join(" ")}/>
                  <p className={[b.mr1,"blueToWhite",b.mb0,'tp-name'].join(" ")}>
                    {newData.was_selected}
                  </p>
                </span>
                <span className={["firm-row-likes-text","product-box-sm-pt",'font-size-13',b.fontWeightNormal,'blueToWhite'].join(" ")}>
                  <Translate tKey="chose this offer" />
                </span>
              </div>
            </div>
          </div>
          <div className={[b.colSm12, b.dFlex].join(" ")}>
            <div className={["product-box-img-box",b.colAuto,b.p1,"dark-mode-image",b.mt2,].join(" ")}>
              <Link to={newData.url}><Thumbnail image={newData.image} alt={newData.name}/></Link>
            </div>
            {
              newData.content?.map(([name, value], i) => (
                <div className={[ b.colAuto].join(" ")} key={i}>
                  <p className={["product-box-fs",b.mb0,b.pt2,"blackToGray",].join(" ")}>
                    {name}
                  </p>
                  <p className={["product-box-fs2", b.mb0].join(" ")}>
                    {value}
                  </p>
                </div>
              ))
            }
          </div>
        </div>
        <div className={[b.colLg,b.justifyContentEnd,b.dFlex].join(" ")}>
          <div className={["product-box-buttons"].join(" ")}>
            {newData.productType === "cards" && <Out className={[b.btn,"btn-info","btn-normal",b.mb0,"button-256",b.fontWeightNormal,b.mt0].join(" ")} item={item} defaultDescription="Put on card" defaultIcon="Taking-money"/>}
            {newData.productType === "firm" && <Out className={[b.btn,"btn-info","btn-normal",b.mb0,"button-256",b.fontWeightNormal,b.mt0].join(" ")} item={item} defaultDescription="Apply" defaultIcon="Taking-money"/>}
            {newData.productType === "credits" && <Out className={[b.btn,"btn-info","btn-normal",b.mb0,"button-256",b.fontWeightNormal,b.mt0].join(" ")} item={item} defaultDescription="Apply credit" defaultIcon="Taking-money"/>}
            {newData.productType === "accounts" && <Out className={[b.btn,"btn-info","btn-normal",b.mt1,b.mb0,b.fontWeightNormal,].join(" ")} item={item} defaultDescription="Create account" defaultIcon="Taking-money" />}
          
            <div className={["button-256",b.mt3,"product-box-comparison-dflex",b.mt2].join(" ")}>
              <ProductCheckboxComparison item={item} settings={settings} key={item.url}/>
            </div>
          </div>
        </div>

        {newData.points_to_note && (
          <div className={[b.col12,b.mt2].join(' ')}>
            <div className={['products-promo-adv'].join(' ')}>
              <span className={["font-size-18",b.pb2,'blueToWhite'].join(' ')}><Translate tKey="Pay attention to that"/>:</span>
              
              {newData.points_to_note.split('\n').map((item, i) => item.length ? (
                <div className={["font-size-13",b.pt1].join(' ')} key={i}>
                  <img src="/assets/apps-images/GreenCheckbox.svg" alt="Google Play" className={b.mr1} loading="lazy" width="12" height="13"/>
                  {item}
                </div>
              ) : null)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ProductItem.propsTypes = {
  item: PropsType.object.isRequired,
  settings: PropsType.object.isRequired,
  isMobileResolution: PropsType.bool,
  grid: PropsType.oneOf(["row", "column"]),
};

ProductItem.defaultProps = {
  grid: "row",
};

export default ProductItem;
