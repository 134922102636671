import React, { useState } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import axios from 'axios';
import * as b from "./bootstrap.module.css";
import Charger from "./charger";
import CommentsForm from "../components/comments-form";
import Icon from "../components/icon.tsx";
import BlogContent from "../components/blog-content";
import Translate from "./translate";

const typeTitle = {
  credit: "Opiniones sobre préstamos en efectivo en %",
  account: "Opiniones de cuentas bancarias %",
  standard: "% Opiniones",
  comments: "Comentarios",
};

const getCountOpinion = (up, down) => {
  const sum = up - down;

  return `${sum >= 1 ? "+" : ""}${sum}`;
};

const Comments = ({ items, title, itemName, description, descHeader, formID, commentType, commentID }) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [parentId, setParentId] = useState(0);

  const toggleForm = (parentId = 0) => {
    setIsOpenForm(!isOpenForm);
    setParentId(parentId);
  }

  const rate = (mode, commentID) => (event) => {
    event.preventDefault();

    const data = new FormData()

    data.append('type', commentType)
    data.append('id', formID)
    data.append('mode', mode)
    data.append('comment', commentID)


    const commentsForm = new URLSearchParams(data).toString();

    const headers = { "Content-Type": "multipart/form-data" };
    const counter = mode === 'up' ? event.currentTarget.nextElementSibling : event.currentTarget.previousElementSibling

    axios
      .post('https://cms.loando.mx/api/comments/rate', commentsForm, headers)
      .then(response => {
        counter.textContent = response.data.value > 0 ? `+ ${response.data.value}` : response.data.value === 0 ? '0' : response.data.value
      })
      .catch(error => {
        console.log(error);
      })
  }

  return (
    <section className={["comments", b.my5, b.pyLg5].join(" ")}>
      <header className={[b.row, b.alignItemsCenter].join(" ")}>
        <h2 className={["title", b.colMd, b.pt0].join(" ")}>
          {descHeader === 0 ? typeTitle[title].replace("%", itemName) : descHeader}
        </h2>
      </header>

      {description && (
        <BlogContent content={description} className={[b.pr1, b.mb4].join(" ")} />
      )}

      <Charger list={items} step={3} countItem={3}>
        {({ customList, isLoaded, loadMore }) => (
          <>
            {items.length > 0 ? (
              customList.map(
                ({
                  mysqlId,
                  author,
                  text,
                  up,
                  down,
                  rating,
                  created_date,
                  subcomments,
                }) => (
                  <article key={mysqlId} className={["comment", b.card, b.mb3, b.px3, b.pt3, b.pb0, b.mbLg4, b.ptLg3, b.pxLg4, b.pbLg0,].join(" ")}>
                    <header className="comment-header">
                      <div className={[b.dFlex, b.mx0, b.alignItemsCenter].join(" ")}>
                        <span className={["comment-letter", b.mr2].join(" ")}>
                          {author.slice(0, 1)}
                        </span>
                        <div>
                          <span className="font-size-13"><Translate tKey="Review from" />:</span>
                          <h5 className={["comment-title", b.h5, b.mb0].join(" ")}>
                            {author}
                          </h5>
                        </div>
                      </div>
                      <div className={[b.mt2, b.dFlex].join(" ")}>
                        {[1, 2, 3, 4, 5].map((el) =>
                          rating >= el ? (
                            <div className="comments-star-box" key={el}>
                              <Icon size={25} icon="Star" className={b.mr1} />
                            </div>
                          ) : (
                            <div className="empty-star-box" key={el}>
                              <Icon size={25} icon="Empty-star" className={b.mr1} />
                            </div>
                          )
                        )}
                      </div>
                    </header>
                    <div className="comment-opinion">
                      <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                        <p className={[b.mt0, b.mb3].join(" ")}>
                          <Translate tKey="Is this opinion useful?" />
                        </p>
                      </div>
                      <div className={[b.dFlex, b.justifyContentBetween, b.alignItemsCenter, "comment-padding",].join(" ")}>
                        <button className={[b.dFlex, b.alignItemsCenter, 'btn-hidden', 'blueToWhite'].join(" ")} onClick={rate('up', mysqlId)}>
                          <Translate tKey="Yes" />
                          <Icon size={20} icon="Thumb-up-color" className={b.ml2} />
                        </button>
                        <span className="blackToWhite">
                          {getCountOpinion(up, down)}
                        </span>
                        <button className={[b.dFlex, b.alignItemsCenter, b.textCapitalize, 'btn-hidden', 'blueToWhite'].join(" ")} onClick={rate('down', mysqlId)}>
                          <Translate tKey="No" />
                          <Icon size={20} icon="Thumb-down-color" className={b.ml2} />
                        </button>
                      </div>
                    </div>
                    <div className={["comment-body", "dGrid"].join(" ")}>
                      {text}
                      <span className={["comment-date", b.mt3].join(" ")}>
                        {created_date}
                      </span>
                      <button className={["btn-hidden", "comment-btn", b.pt2].join(" ")} onClick={() => toggleForm(mysqlId)}>
                        <Icon size={25} icon="Undo" className={b.mr2} />
                        <Translate tKey="Reply" />
                      </button>
                    </div>

                    <div className="comment-answers">
                      <Charger list={subcomments} step={3} countItem={1}>
                        {({
                          customList: customSub,
                          isLoaded: subIsLoaded,
                          loadMore: loadMoreSub,
                        }) => (
                          <>
                            {customSub.map((sub) => (
                              <section key={sub.mysqlId} className={["subcomment", b.card, b.mb3, b.p3, b.pLg3,].join(" ")}>
                                <header className="comment-header">
                                  <div className={[b.dFlex, b.mx0, b.alignItemsCenter, b.colLg].join(" ")}>
                                    <Icon size={30} icon="Response-arrow" className={[b.mr2, b.mb2].join(" ")} />
                                    <div>
                                      <span className="font-size-13">
                                        <Translate tKey="Response" />:
                                      </span>
                                      <h6 className={["subcomment-title", b.h5, b.mb3,].join(" ")}>
                                        {sub.author}
                                      </h6>
                                    </div>
                                  </div>
                                </header>
                                <div className={["comment-opinion"].join(' ')}>
                                  <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                                    <p className={[b.mt0, b.mb3].join(" ")}>
                                      Czy ta odpowiedź była przydatna?
                                    </p>
                                  </div>
                                  <div className={[b.dFlex, b.justifyContentBetween, b.alignItemsCenter, "comment-padding",].join(" ")}>
                                    <button className={[b.dFlex, b.alignItemsCenter, 'btn-hidden', 'blueToWhite'].join(" ")} onClick={rate('up', sub.mysqlId)}>
                                      <Translate tKey="Yes" />
                                      <Icon size={20} icon="Thumb-up-color" className={b.ml2} />
                                    </button>
                                    <span className="blackToWhite">
                                      {getCountOpinion(up, down)}
                                    </span>
                                    <button className={[b.dFlex, b.alignItemsCenter, b.textCapitalize, 'btn-hidden', 'blueToWhite'].join(" ")} onClick={rate('down', sub.mysqlId)}>
                                      <Translate tKey="No" />
                                      <Icon size={20} icon="Thumb-down-color" className={b.ml2} />
                                    </button>
                                  </div>
                                </div>
                                <div className={["subcomment-body", 'blackToWhite'].join(' ')}>
                                  {sub.text}
                                </div>
                              </section>
                            ))}
                            <div>
                              {subIsLoaded && (
                                <button onClick={loadMoreSub} className={["btn-hidden", "comment-btn", b.mb3, b.mr3,].join(" ")}>
                                  <Icon size={20} icon="Buffering" className={[b.mr1, "icon-blog"].join(" ")} />
                                  <Translate tKey="Load more comments" />
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </Charger>
                    </div>
                  </article>
                )
              )
            ) : (
              <div className={['team-person', 'product-box', b.p3, b.pbLg5, b.ptLg4, b.textCenter].join(' ')}>
                <div className={[b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>
                  <img src="/assets/Rysiek-empty-comments.svg" alt="Rysiek" />
                </div>
                <h2 className="font-size-24"><Translate tKey="Leave your comment!" /></h2>
                <span className={[b.mt2, 'blueToWhite', b.fontWeightNormal, 'font-size-13'].join(' ')}><Translate tKey="Rysiek searched and searched" /></span>
                <div className={[b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>
                  <button className={[b.btn, "btn-info", "btn-cta", "button-256", b.mt4, "ml-deskt"].join(" ")} onClick={toggleForm} id="addComment">
                    <Icon color="#ffffff" size={20} icon="Pen" className={[b.mr1, "icon-blog"].join(" ")} />
                    <Translate tKey="Add comment" />
                  </button>
                </div>
              </div>
            )}
            <div className={[b.dSmFlex, b.justifyContentCenter, b.px0, b.pxLg4,].join(" ")}>
              {isLoaded && (
                <button onClick={loadMore} className={[b.btn, "btn-light", "btn-cta", "button-256", b.my2,].join(" ")}>
                  <Icon size={20} icon="Buffering" className={b.mr1} />
                  <Translate tKey="Load more reviews" />
                </button>
              )}
              {items.length > 0 && (
                <button className={[b.btn, "btn-info", "btn-cta", "button-256", b.my2, "ml-deskt"].join(" ")} onClick={toggleForm} id="addComment">
                  <Icon color="#ffffff" size={20} icon="Pen" className={[b.mr1, "icon-blog"].join(" ")} />
                  <Translate tKey="Add comment" />
                </button>
              )}
            </div>
          </>
        )}
      </Charger>
      <CommentsForm isOpen={isOpenForm} close={toggleForm} parentId={parentId} formID={formID} commentType={commentType} />
    </section>
  );
};

Comments.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string,
  itemName: PropTypes.string,
  description: PropTypes.string,
  descHeader: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  formID: PropTypes.number,
  commentType: PropTypes.string,
  commentID: PropTypes.number,
};

Comments.defaultProps = {
  items: [],
  itemName: "",
  description: "",
};

export default Comments;

export const query = graphql`
  fragment CommentData on MysqlComments {
    mysqlId
    author
    text
    up
    down
    rating
    created_date
    item
    type
    subcomments {
      mysqlId
      author
      text
    }
  }
`;